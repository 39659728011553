<template>
  <v-card outlined>
    <v-card-text class="px-3 py-1 d-flex align-center">
      <v-icon v-if="isSuccess" color="success" class="mr-1">
        mdi-checkbox-marked-circle-outline
      </v-icon>
      <v-icon v-if="isError" color="error" class="mr-1">
        mdi-alert-circle-outline
      </v-icon>
      <div class="filename__text text-caption">{{ value.name }}</div>
      <v-chip class="ml-1 mr-2 flex-shrink-0" x-small>
        {{ fileSize(value.size) }}
      </v-chip>
      <v-btn v-if="isError" icon @click="$emit('reload')">
        <v-icon>mdi-cached</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn icon @click="$emit('remove')">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-card-text>

    <v-alert v-if="value.error" class="mb-3 mx-3" dense outlined type="error">
      {{ value.error }}
    </v-alert>

    <v-progress-linear
      v-if="isProgress"
      :color="progressColor"
      indeterminate
      absolute
      bottom
    />
  </v-card>
</template>

<script>
import { fileSize } from '@/utils/helpers';

export default {
  props: {
    value: {
      required: true,
      type: Object, // Class "Attachment"
    },
  },

  computed: {
    status() {
      return this.value.status;
    },

    isError() {
      return this.status === 'error' || this.value.error;
    },

    isProgress() {
      return this.status === 'sending';
    },

    isSuccess() {
      return !this.isError && this.status === 'success';
    },

    progressColor() {
      if (this.status === 'sending') return 'blue';

      return 'primary';
    },
  },

  methods: {
    fileSize,
  },
};
</script>

<style lang="scss" scoped>
.filename__text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
