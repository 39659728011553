<template>
  <ModernModalLayout
    title="Написать в поддержку"
    :watch-for="form"
    :loading="$wait('isFetchPhone')"
  >
    <div>
      <v-expansion-panels flat tile multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>От кого</v-expansion-panel-header>
          <v-expansion-panel-content>
            <DefinitionList dense :value="userInfo" />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="type === SUPPORT_TOPICS_TYPES['INSPECTION']">
          <v-expansion-panel-header>
            Диагностические данные
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="width: 450px; overflow: auto">
              <pre style="font-size: 0.8rem">{{ details }}</pre>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-form class="mt-3 mb-3" :disabled="$wait('createIssue')">
      <v-select
        v-if="details"
        v-model="topic"
        label="Выберите тему сообщения"
        :items="topics"
        item-value="id"
        item-text="name"
        outlined
        depressed
        dense
        return-object
        :error-messages="getValidationErrors('form.topicId')"
      />
      <v-text-field
        v-model="form.email"
        label="Email"
        hint="Оставьте свой e-mail для обратной связи"
        outlined
        depressed
        dense
        :error-messages="getValidationErrors('form.email')"
      />
      <PhoneField
        v-if="!$wait('isFetchPhone')"
        v-model="form.phone"
        hint="Оставьте свой номер телефона, чтобы мы могли Вам перезвонить"
        outlined
        depressed
        dense
        :error-messages="getValidationErrors('form.phone')"
      />
      <v-textarea
        v-model="form.description"
        label="Сообщение"
        outlined
        depressed
        dense
        :error-messages="getValidationErrors('form.description')"
      />
      <div>
        <AttachmentItem
          v-for="attachment in attachments"
          :key="attachment.key"
          class="mb-2"
          :value="attachment"
          @reload="reloadAttachment(attachment)"
          @remove="removeAttachment(attachment)"
        />
        <v-file-input
          ref="fileinput"
          label="Прикрепить файлы"
          outlined
          dense
          depressed
          multiple
          :error-messages="getValidationErrors('files')"
          @change="uploadImage"
        />
      </div>
    </v-form>

    <template #actions:append>
      <v-btn
        class="px-5"
        depressed
        color="primary"
        :disabled="$wait('createIssue')"
        @click="onSubmit"
      >
        Отправить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, email } from 'vuelidate/lib/validators';

// api
import processingApi from '@/api/services/processing';
import supportApi from '@/api/services/support';

import axios from '@/plugins/axios';
import { Sentry } from '@/plugins/sentry';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import { phone } from '@/utils/validators';
import { name } from '@/utils/convert';
import { HTTP_CODES, SUPPORT_TOPICS_TYPES } from '@/utils/constants';
import { maskTextPhone, unmaskPhone } from '@/utils/masks';
import Attachment from '@/models/Attachment';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import DefinitionList from '@/components/DefinitionList';
import AttachmentItem from '@/components/AttachmentItem';
import PhoneField from '@/components/controls/PhoneField';
import env from '@/plugins/env';

export default {
  components: {
    ModernModalLayout,
    DefinitionList,
    AttachmentItem,
    PhoneField,
  },
  mixins: [validationMixin, waitable],

  props: {
    type: { type: String, default: SUPPORT_TOPICS_TYPES['GENERAL'] },
    details: { type: Object, default: null },
  },

  data: () => ({
    topics: [],
    topic: null,
    accountData: null,

    attachments: [],

    form: {
      topicId: null,
      email: null,
      phone: null,
      description: null,
      attachmentIds: [],
    },
  }),

  validations: {
    form: {
      topicId: { required },
      email: { required, email },
      phone: { phone },
      description: {
        required,
        maxSymbolsLength: maxLength(500),
      },
    },
  },

  computed: {
    ...mapGetters('AUTH', ['currentAccount']),
    SUPPORT_TOPICS_TYPES() {
      return SUPPORT_TOPICS_TYPES;
    },

    userInfo() {
      const acc = this.currentAccount;
      const list = [
        { label: 'ФИО', value: name(acc.profile) },
        { label: 'Роль', value: acc.role?.name || '–' },
      ];
      return list;
    },
  },

  watch: {
    topic(val) {
      this.form.topicId = val?.id || null;
    },
  },

  async created() {
    await this.fetchTopics();
  },

  mounted() {
    this.form.email = this.currentAccount?.email || null;
    this.form.details = this.details || {};

    this.fetchPhone();
  },

  methods: {
    async fetchPhone() {
      if (this.currentAccount.type === 'medic') {
        this.$loading(
          processingApi
            .medicGet(this.currentAccount.referenceId)
            .then(({ phone }) => {
              this.form.phone = maskTextPhone(phone);
            }),
          'isFetchPhone',
        );
      }
    },

    async fetchTopics() {
      const topics = await this.$loadingNotify(
        supportApi.getTopics(this.type),
        'getTopics',
        'При загрузке тем сообщений произошла ошибка',
        null,
      );

      this.topics = Object.freeze(topics);
    },

    async uploadImage(files) {
      if (!files.length) return;
      for (const file of files) {
        const newAttachment = new Attachment(file);
        const foundDuplicate = this.attachments.find(
          el => el.key === newAttachment.key,
        );
        if (!foundDuplicate) {
          this.attachments.push(newAttachment);
          this.sendAttachment(newAttachment);
        }
      }

      if (this.$refs.fileinput) {
        this.$refs.fileinput.blur();
        this.$refs.fileinput.clearableCallback();
      }
    },

    reloadAttachment(attachment) {
      this.sendAttachment(attachment);
    },

    removeAttachment(attachment) {
      if (attachment.cancelToken) attachment.cancelToken.cancel();

      const foundIndex = this.attachments.findIndex(el => el === attachment);
      this.attachments.splice(foundIndex, 1);
    },

    async sendAttachment(attachment) {
      attachment.clearError();
      attachment.status = 'sending';
      try {
        attachment.cancelToken = axios.CancelToken.source();
        const { id } = await supportApi.createAttachment(attachment.file, {
          cancelToken: attachment.cancelToken.token,
        });
        attachment.status = 'success';
        attachment.id = id;
      } catch (e) {
        const status = e?.response?.status;
        if (status) attachment.setError(HTTP_CODES[status]);
        else attachment.setError(e?.message || e);
      }
    },

    async sendIssue(payload) {
      return this.$loadingNotify(
        supportApi.issueCreate(payload),
        'createIssue',
        'При отправке произошла ошибка',
        'Ваше сообщение отправлено!',
      );
    },

    async onSubmit() {
      // NOTE: HARDCODE
      // topic field is visible only if support modal was opened from medcab
      // inspection page. So in many other cases we set topic default to
      // 'general' (Другое)
      // FIXME: Но он (список) не поменяется, этому списку сто лет. Можно
      // параллельно завести улучшение, чтобы с фронта ничего не передавать
      // вообще, а на беке также хардкорно реагировать. (c) Томашкевич
      if (!this.topic) {
        this.topic = {
          id: 23,
          name: 'Другое',
          type: 'general',
        };
        this.form.topicId = 23; // watcher doesn't fire as fast
      }

      this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        ...this.form,
        phone: unmaskPhone(this.form.phone) || null,
        attachmentIds: this.attachments.map(el => el.id).filter(_ => _),
      };

      if (env.get('VUE_APP_SENTRY_DSN')) {
        const sentryEventId = await Sentry.captureMessage(
          `To support: ${this.topic.name}`,
          scope => {
            scope.setLevel('log');
            scope.setTag('topic', this.topic.id);
            scope.setContext('Message', this.form);
          },
        );

        payload.details._sentry_event_id = sentryEventId;
      }

      await this.sendIssue(payload);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-expansion-panel-header {
    padding: 0;
    height: 34px;
    min-height: 34px;
  }
  .v-expansion-panel--active:not(:first-child),
  .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 0 10px;
  }
}
</style>
