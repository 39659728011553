class Attachment {
  constructor(file) {
    if (!(file instanceof File))
      throw new Error(
        'The argument field "file" does not implement the File type.',
      );

    this.file = file;

    this.name = this.file.name;
    this.size = this.file.size;
    this.type = this.file.type;
    this.lastModified = this.file.lastModified;

    /**
     * Unique key
     * @description Used to check for duplicates
     */
    this.key = this.name + this.size + this.lastModified;

    /**
     * ID
     * @description Is assigned after the file is uploaded to the server
     * @type {Number}
     */
    this.id = null;

    /**
     * Status file
     * @var {"sending" | "error" | "success"}
     */
    this.status = null;

    /**
     * Error message
     * @type {null | String}
     */
    this.error = null;

    /**
     * Cancel token for stop XHR
     * @type {axios.CancelToken}
     */
    this.cancelToken = null;
  }

  setError(errorMessage) {
    this.status = 'error';
    this.error = errorMessage;
  }

  clearError() {
    this.error = null;
  }
}

export default Attachment;
